.solutions-container {
  display: grid;
  place-content: center;

  .solution-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    pointer-events: none;
  }

  .solution-card {
    max-width: 300px;
    min-height: 300px;
    color: var(--light);
    padding: 30px;
    background-color: var(--dark);
    border: 1px solid rgba(116, 68, 219, 0.5);
    border-radius: 0px;
    pointer-events: auto;
    transform: scale(1);
    opacity: 1;
    transition: all 0.25s ease-in-out;
    display: flex;
    flex-direction: column;

    .solution-card-title {
      position: relative;
      font-weight: bold;
      color: var(--primary);
    }

    .solution-card-description {
      font-size: 14px;
    }
  }
}

.solution-cards:hover > .solution-card:hover {
  transform: scale(1.05);
  background: linear-gradient(
    to right bottom,
    var(--primary),
    var(--secondary)
  );

  box-shadow: 0 0 150px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.solution-cards:hover > .solution-card:hover .solution-card-title {
  color: var(--light);
}

.solution-cards:hover > .solution-card:not(:hover) {
  opacity: 0.5;
}

.roadmap {
  position: relative;
  width: 860px;
  margin: 0px auto;
  padding: 20px;
  list-style-type: none;
  transition: all 0.3s ease;

  @media (max-width: 860px) {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  &:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: " ";
    display: block;
    width: 5px;
    border-radius: 25px;
    height: 100%;
    margin-left: -3px;
    background: linear-gradient(
      to right bottom,
      var(--primary),
      var(--secondary)
    );
    z-index: 0;
  }

  li {
    @media (max-width: 860px) {
      padding: 2em 0;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}

.roadmap-dir-l {
  position: relative;
  width: 400px;
  float: left;
  text-align: right;

  .roadmap-img {
    position: absolute;
    left: 110%;
    width: 200px;
  }

  @media (max-width: 860px) {
    float: none;
    width: 100%;
    text-align: center;

    .roadmap-img {
      display: none;
    }
  }
}

.roadmap-dir-r {
  position: relative;
  width: 400px;
  float: right;

  .roadmap-img {
    position: absolute;
    right: 110%;
    width: 200px;
  }

  @media (max-width: 860px) {
    float: none;
    width: 100%;
    text-align: center;

    .roadmap-img {
      display: none;
    }
  }

  .roadmap-title {
    &:before {
      left: -32px;
    }
  }
}

.title-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;

  @media (max-width: 860px) {
    text-align: center;
  }
  .roadmap-title {
    position: relative;
    display: inline;
    padding: 12px 20px;
    border-radius: 50px;
    margin: 12px;
    font-weight: 600;
    text-align: left;
    font-size: 20px;
    color: var(--light);
    background: linear-gradient(
      to right bottom,
      var(--primary),
      var(--secondary)
    );

    @media (max-width: 860px) {
      background: var(--light);
      color: var(--primary);
      z-index: 15;
    }
  }
}

.roadmap-dir-l .roadmap-title:before,
.roadmap-dir-r .roadmap-title:before {
  position: absolute;
  top: 50%;
  right: -32px;
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background: var(--light);
  border-radius: 10px;
  border: 4px solid var(--primary);
  z-index: 10;

  @media (max-width: 860px) {
    position: absolute;
    top: -30px;
    left: 50%;
    content: " ";
    display: block;
    margin-left: -10px;
  }
}

.roadmap-desc {
  margin: 30px 10px;
  font-size: 18px;
  line-height: 30px;
  border-radius: 10px;
  background: transparent;
  padding: 10px 10px;
  color: var(--light);

  @media (max-width: 860px) {
    position: relative;
    margin: 30px 30px 0 30px;
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(
      to right bottom,
      var(--primary),
      var(--secondary)
    );
    z-index: 15;
  }
}

.faq-item {
  width: 60%;
  color: var(--light);
  background-color: transparent;
  overflow: hidden;
  border-radius: 0px;
  border-bottom: 4px solid var(--primary);

  &:hover {
    text-shadow: 1px 1px 40px var(--secondary);
  }

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    letter-spacing: 0.5px;
    cursor: pointer;
    font-size: 24px;
    user-select: none;

    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      transform: rotate(90deg);
      text-align: center;
      transition: all 0.2s;
    }
  }

  &-content {
    max-height: 0;
    padding: -0.2em 1em;
    color: var(--light);
    opacity: 0;
    cursor: default;
    font-size: 18px;
    transition: all 0.2s;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    color: var(--dark);
    cursor: pointer;
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

input:checked {
  + .faq-item-label {
    transition: all 0.2s;
    font-weight: bold;
    color: var(--primary);
    &::after {
      transform: rotate(270deg);
    }
  }

  ~ .faq-item-content {
    opacity: 1;
    max-height: 100%;
    padding: 1em;
  }
}

.help-card {
  max-width: 200px;
  min-height: 200px;
  color: var(--light);
  padding: 20px;
  margin: 10px;
  background-color: var(--dark);
  border: 1px solid rgba(116, 68, 219, 0.5);
  border-radius: 0px;
  pointer-events: auto;
  transform: scale(1);
  opacity: 1;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;

  .help-card-description {
    margin: auto 0;
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .solutions-container {
    .solution-cards {
      grid-template-columns: auto;
    }
  }

  .faq-item {
    width: 90%;
  }
}

.promotional-card {
  max-width: 60%;
  color: var(--light);
  padding: 20px;
  background: var(--dark);
  border: 1px solid rgba(116, 68, 219, 0.5);
  border-radius: 0px;
  pointer-events: none;
  margin: 0 auto;
  transform: scale(1);
  opacity: 1;
  transition: all 0.25s ease-in-out;
}
