@import url("https://fonts.googleapis.com/css2?family=Poppins");

:root {
  --bg: #151133;
  --bg-2: #2b1d4e;

  --primary: #6634db;
  --primary-light: #a589f1;

  --secondary: #8a10b7;
  --tertiary: #00c7a2;

  --dark: #2a213f;
  --light: #f2eefa;
}

* {
  user-select: none;
  font-family: "Poppins", sans-serif;
}

body {
  background: url("../assets/bg.jpg");
  background-size: 100%;
  background-color: #151133;
  background-image: radial-gradient(
    49deg,
    #151133 0%,
    #2b1d4e 68%,
    #3d188d 50%
  );
  overflow-x: hidden;
}

.new-card {
  background-color: var(--dark);
  border: solid 2px var(--primary);
  border-radius: 0px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.navbar {
  background: linear-gradient(to bottom, var(--bg), transparent);
  backdrop-filter: blur(1px);
}

.footerbar {
  background: linear-gradient(to top, var(--bg), transparent);
  backdrop-filter: blur(1px);
}

.nav-link {
  height: 60px !important;
  width: 109px;
  text-align: center;
  color: var(--light) !important;
  font-size: 13.5px !important;
  transition: all 0.15s ease-in;
  border-bottom: 4px solid transparent;
}

.nav-link:hover {
  text-shadow: 0px 0px 15px var(--primary-light);
  transform: translateY(-3px);
  border-color: var(--primary);
}

.dropdown-menu-right {
  background-color: transparent;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .navbar .navbar-collapse {
    text-align: center;
  }

  body {
    background: url("../assets/bg-mobile.jpg");
    background-size: 100%;
    background-repeat: repeat-y;
  }
}

.main-btn {
  font-size: 16px;
  font-weight: 600;
  background-color: var(--tertiary);
  color: var(--dark);
  border-radius: 50px;
  border: none;
  outline: none;
  padding: 15px 20px;
  transition: 0.15s ease-in;
}

.main-btn:hover {
  transform: translateX(3px);
  background: linear-gradient(
    to right bottom,
    var(--tertiary),
    var(--secondary)
  );
}

.main-btn:hover i {
  transform: translateX(3px);
}

.main-btn i {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--dark);
  transition: 0.15s ease-in;
}

.main-p {
  color: var(--light);
  font-size: 18px;
  text-shadow: 1px 1px 8px var(--dark);
  text-align: center;
  line-height: 36px;
  width: 50%;
}

.seperator {
  margin: 20px auto;
  margin-bottom: 25px;
  border-top: 5px solid var(--primary);
  width: 40%;
  transform: rotate(-3deg);
}

.home-text {
  font-size: 60px;
  color: var(--light);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: var(--dark);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.social-menu ul {
  display: flex;
  justify-content: center;
}

.social-menu ul li {
  list-style: none;
  margin: 0;
}

.social-menu ul li .fab {
  font-size: 24px;
  line-height: 48px;
  transition: 0.2s;
}

.social-menu ul li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  color: var(--primary-light);
  transition: 0.1s;
}

.social-menu ul li a:hover {
  transform: translateY(-3px);
  color: var(--secondary);
  transition: 0.1s;
}

.main-heading {
  color: var(--light);
  font-size: 48px;
  font-weight: 600;
  text-shadow: 1px 1px 75px var(--secondary);
  transition: all 0.3s ease;
}

.main-heading:hover {
  letter-spacing: 2px;
}

.mint-container {
  width: 40vw;
  height: 60vh;
  color: var(--light);
  padding: 40px;
}

.mint-text {
  color: var(--primary);
  font-size: 28px;
  padding: 5px 25px;
  min-width: 40px;
  margin: 0px 0px 35px 0px;
  border-bottom: 3px solid var(--primary);
}

.mint-container a {
  color: var(--primary);
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .mint-container {
    margin-top: 30px;
    width: 80vw;
  }

  .home-text {
    font-size: 32px;
  }

  .nav-link {
    width: 100%;
  }

  .main-p {
    width: 95%;
  }
}

.media-icon {
  width: 28px;
  margin-top: 10px;
  transition: 0.4s;
}

.media-icon:hover {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) brightness(10);
}

@media only screen and (max-width: 992px) {
  .main-heading {
    font-size: 42px;
  }

  p {
    font-size: 20px !important;
  }

  html {
    overflow: scroll;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.main-btn:disabled {
  cursor: not-allowed;
}

.mint-btn-2 {
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: var(--primary);
  transition: all 0.8s ease 0s;
}

.mint-btn-2:hover {
  background-color: var(--primary-light);
  transition: all 0.8s ease 0s;
}

.team-card {
  height: 380px;
  width: 300px;
  transition: background 0.5s;
  overflow: hidden;
  background: var(--dark);
  box-shadow: 0 70px 63px -60px var(--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid var(--primary);
}

.card-border {
  position: relative;
  height: 100%;
  width: 100%;
  background: transparent;
  transition: border 0.5s;
}

.card-body {
  position: absolute;
  bottom: 0;
  padding: 0;
  opacity: 0.25;
  transform: translateY(60px);
  background: rgba(0, 0, 0, 0.75);
  border-top: 2px solid var(--primary);
  transition: all 0.5s;
}

.card-template {
  transition: all 0.5s;
}

.card-template h2,
.card-template h5 {
  text-align: left;
  color: var(--light);
  margin: 20px;
  transition: opacity 0.5s;
}

.card-template:hover .card-body {
  transform: translateY(0px);
  opacity: 1;
}

.form-input {
  background-color: transparent;
  border: 2px solid var(--primary);
  border-radius: 50px;
  padding: 20px 20px;
  width: 40%;
  font-size: 18px;
  color: var(--light);
  transition: 0.3s ease;
  margin: 40px;
  outline: none;
}

.form-input:focus,
.form-input:hover {
  border: 2px solid var(--tertiary);
}

.alert.error {
  color: var(--accentDark);
}

.alert.sending {
  color: var(--accent);
}

.alert.success {
  color: var(--accentLight);
}
